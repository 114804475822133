import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
         


                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/77.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/6.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/88.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/99.svg" alt="Logo Images"/>
                    </li>
                 
                </ul>

                <p style={{ color: 'white' }}>Pour soutenir l'événement contactez-nous ! - contact@herlan.fr </p>

            </React.Fragment>
        )
    }
}
export default BrandTwo;