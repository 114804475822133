import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";


class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded')
        })
    }


    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }


    render() {
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color = 'default-color' } = this.props;
        let logoUrl;
        if (logo === 'light') {
            logoUrl = <img src="/assets/images/logo/logo2.png" alt="Herlan" style={{ width: '150px', height: 'auto' }} />;

        } else if (logo === 'dark') {
            logoUrl = <img src="/assets/images/logo/logo2.png" alt="Herlan" style={{ width: '150px', height: 'auto' }} />;

        } else if (logo === 'symbol-dark') {
            logoUrl = <img src="/assets/images/logo/logo2.png" alt="Herlan" style={{ width: '150px', height: 'auto' }} />;

        } else if (logo === 'symbol-light') {
            logoUrl = <img src="/assets/images/logo/logo2.png" alt="Herlan" style={{ width: '150px', height: 'auto' }} />;

        } else {
            logoUrl = <img src="/assets/images/logo/logo2.png" alt="Herlan" style={{ width: '150px', height: 'auto' }} />;


        }


        return (
            <header className={`header-area formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li ><Link to="/">ACCUEIL</Link>

                                </li>


                                <li >
                                    <a href="https://discord.gg/XrKPdgRF6V" target="_blank" rel="noopener noreferrer">
                                        FAQ
</a>
                                </li>


                                <li>  <a href="https://twitter.com/her_lan_">
                                    <span>TWITTER</span>
                                </a>
                                </li>
                                <li>  <a href="https://discord.gg/XrKPdgRF6V">
                                    <span>DISCORD</span>
                                </a>
                                </li>


                            </ul>
                        </nav>
                        <div className="header-btn">
                            <a className="rn-btn" href="https://play.toornament.com/fr/tournaments/8119668577564565504/">
                                <span>Inscriptions</span>
                            </a>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;