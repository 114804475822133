import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: "Promotion de l'inclusion et de la diversité",
        description: "HerLan offre une plateforme pour les femmes qui veulent se lancer dans l'esport, en leur offrant une occasion de concourir dans un environnement inclusif et sécurisé."
    },
    {
        icon: '02',
        title: 'Créer une communauté plus égalitaire ',
        description: "HerLan contribue à briser les stéréotypes de genre et à créer une communauté plus diversifiée et plus égalitaire dans le monde de l'esport."
    },
    {
        icon: '03',
        title: "Augmenter la visibilité des femmes dans l'esport",
        description: "HerLan contribue à accroître la visibilité des femmes dans l'esport en mettant en avant leurs réussites et leurs performances."
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;