import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Je suis fier de lancer HerLan, un tournoi d'esport exclusivement pour les femmes. Je veux donner aux femmes une plateforme pour montrer leur talent et inspirer les autres. HerLan est plus qu'un simple tournoi, c'est une communauté forte et inclusive pour les femmes dans l'esport.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Lisa </span> - PORTEUSE DU PROJET, HERLAN</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Je suis convaincu que l'esport peut être un véritable vecteur de changement social, mais pour cela, nous devons promouvoir l'inclusion et la diversité. HerLan est une initiative pour y parvenir et je suis ravi de pouvoir contribuer à cette cause.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Adel </span> - CO-ORGANISATEUR, HERLAN</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>En organisant HerLan, je veux montrer que l'esport n'est pas réservé aux seuls joueurs masculins. Les femmes ont aussi leur place dans cet univers et je veux leur donner la visibilité qu'elles méritent.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Jannie</span> - CO-ORGANISATRICE, HERLAN</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Je suis passionné d'esport depuis longtemps et je veux partager cette passion avec le plus grand nombre. HerLan est une façon de le faire en offrant aux femmes une occasion unique de jouer, de se rencontrer et de s'amuser ensemble dans un environnement bienveillant et inclusif.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Olipe</span> - CO-ORGANISATRICE, HERLAN</h6>
                                    </div>
                                </div>
                            </TabPanel>
                          
                         
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/IMG_9033.jpeg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                        <img src="/assets/images/client/1.jpeg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/6.jpeg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/9.jpeg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                             
                              
                             
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;